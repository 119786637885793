
import Vue from "vue";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "Create",

  components: {
    FileUploader,
    MultiplePhonePicker
  },

  data: () => ({
    model: {
      name: "" as string,
      password: "" as string,
      email: "" as string,
      password_confirmation: "" as string,
      position_id: null as any,
      phone: [""] as any
    } as any,
    selects: {
      roles: [] as any,
      statuses: [] as any
    } as any,
    errorMessages: {} as any
  }),

  computed: {
    computedRules(): object {
      return {
        min: this.minLength,
        required: this.required,
        maxNumber: this.max,
        minNumber: this.min,
        samePassword: this.samePassword
      };
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    required(): Function {
      return (v: string): boolean | string =>
        !!v || (this.$t("global_validation.required") as string);
    },
    minLength(number: number): Function {
      return (v: string): boolean | string =>
        v.length >= number || `Минимальная длина: ${number}`;
    },
    max(number: number): Function {
      return (v: string): boolean | string =>
        !v || Number(v) <= number || `Максимальное значение ${number}`;
    },
    min(number: number): Function {
      return (v: string): boolean | string =>
        !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    samePassword(): Function {
      return (v: string): boolean | string =>
        v === this.model.password || `Пароль не совпадает!`;
    },
    async getList(): Promise<void> {
      try {
        this.selects.roles = await this.$API.roles().getList();
        this.selects.statuses = await this.$API.user().getStatuses();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };
          model.phone = model.phone.join(",");
          await this.$API
            .user()
            .create({ ...model, position_id: 4, status_id: 3 });
          await this.$router.push("/managers");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
